import { useTranslation } from "next-i18next";
import { Typography, Button } from "@material-ui/core";

import { StepProps } from "@/components/NotificationForm";

const SuccessStep: React.FC<StepProps> = ({ attempt, resetStep }) => {
  const { t } = useTranslation("notification");

  if (!attempt) {
    resetStep();
    return null;
  }

  return (
    <>
      <Typography component="h2" variant="h3">
        {t("success.subtitle")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("success.phone_number")}
        <span>&nbsp;</span>
        <span>{attempt.phone_number}</span>
      </Typography>
      <Typography variant="body1" paragraph>
        {t("success.notifications")}
      </Typography>
      <Button
        color="inherit"
        variant="contained"
        onClick={resetStep}
        sx={{
          display: "block",
          lineHeight: 2,
        }}
      >
        {t("success.reset")}
      </Button>
    </>
  );
};

export default SuccessStep;
