import { E164Number } from "libphonenumber-js";

interface RegisterPayload {
  phone_number: E164Number;
  language: string;
}

export const register = async (payload: RegisterPayload) => {
  try {
    const response = await fetch(`${process.env.STRAPI_DOMAIN}/registered-phones/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    return {
      ...(await response.json()),
      error: response.status !== 200,
    };
  } catch (err) {
    console.error(err);
    return { error: true };
  }
};

interface VerifyPayload {
  phone_number: E164Number;
  verification_code: string;
}

export const verify = async (payload: VerifyPayload) => {
  try {
    const response = await fetch(`${process.env.STRAPI_DOMAIN}/registered-phones/verify`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    return {
      ...(await response.json()),
      error: response.status !== 200,
    };
  } catch (err) {
    console.error(err);
    return { error: true };
  }
};
