import { E164Number } from "libphonenumber-js";
import { useState, useEffect } from "react";

import VerifyStep from "./VerifyStep";
import SuccessStep from "./SuccessStep";
import RegisterStep from "./RegisterStep";

export interface Attempt {
  phone_number: E164Number;
  timestamp: number;
}

export interface StepProps {
  attempt: Attempt | undefined;
  nextStep: () => void;
  resetStep: () => void;
  storeAttempt: (attempt: Attempt) => void;
}

const renderStep = (step: number, stepProps: StepProps) => {
  switch (step) {
    default:
    case 0:
      return <RegisterStep {...stepProps} />;
    case 1:
      return <VerifyStep {...stepProps} />;
    case 2:
      return <SuccessStep {...stepProps} />;
  }
};

const NotificationForm: React.FC = () => {
  const [attempt, setAttempt] = useState<Attempt>();
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const storedStep = localStorage.getItem("sms-reg-step");
    const storedAttempt = localStorage.getItem("sms-reg-attempt");

    if (storedStep) setActiveStep(parseInt(storedStep));
    if (storedAttempt) setAttempt(JSON.parse(storedAttempt));

    setIsLoading(false);
  }, []);

  useEffect(() => {
    localStorage.setItem("sms-reg-step", `${activeStep}`);
  }, [activeStep]);

  const stepProps: StepProps = {
    attempt,
    nextStep: () => setActiveStep(activeStep + 1),
    resetStep: () => setActiveStep(0),
    storeAttempt: (attempt) => {
      setAttempt(attempt);
      localStorage.setItem("sms-reg-attempt", JSON.stringify(attempt));
    },
  };

  const currentStep = !isLoading && renderStep(activeStep, stepProps);

  return (
    <div className="notification-form">
      {currentStep}
      <style jsx>{`
        .notification-form {
          min-height: 175px;
        }  
      `}</style>
    </div>
  );
};

export default NotificationForm;
