import { useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Formik, Form, FormikHelpers } from "formik";
import { Grid, Typography, TextField, Button } from "@material-ui/core";

import { StepProps } from "@/components/NotificationForm";
import { timeToMinute } from "@/utils";
import { register, verify } from "@/services/phone";

import styles from "./index.module.scss";

interface FormValues {
  verification_code: string;
}

const initialValues: FormValues = {
  verification_code: "",
};

const VerifyStep: React.FC<StepProps> = ({ attempt, storeAttempt, nextStep, resetStep }) => {
  const { t } = useTranslation("notification");
  const { locale: language = "fr" } = useRouter();
  const [submitError, setSubmitError] = useState("");

  if (!attempt) {
    resetStep();
    return null;
  }

  const validate = ({ verification_code }: FormValues) => {
    const errors: { [I: string]: string } = {};
    if (!verification_code) errors.verification_code = t("form.error.required");
    return errors;
  };

  const { phone_number, timestamp } = attempt;

  const handleSubmit = ({ verification_code }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setTimeout(async () => {
      const res = await verify({ verification_code, phone_number });
      if (res.error) {
        setSubmitting(false);
        setSubmitError("verify.submit.error");
      } else {
        nextStep();
        setSubmitError("");
      }
    }, 1000);
  };

  const handleNewRequest = (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    setTimeout(async () => {
      const now = new Date().getTime();
      if (timeToMinute(now - timestamp) > 1) {
        setSubmitError("");
        const res = await register({ phone_number, language });
        if (res.error) { 
          setSubmitError("form.error.submit");
          setSubmitting(false);
        } else {
          storeAttempt({ phone_number, timestamp: now });
        }
      } else {
        setSubmitError("form.error.request_limit");
        setSubmitting(false);
      }
    }, 1000);
  };

  return (
    <Formik validate={validate} onSubmit={handleSubmit} initialValues={initialValues}>
      {({ values, handleChange, touched, errors, isSubmitting, setSubmitting }) => (
        <Form>
          <Typography component="h2" variant="h3">
            {t("verify.subtitle")}
          </Typography>
          <Typography paragraph>{t("verify.description")}</Typography>
          <Typography paragraph>{t("verify.limits")}</Typography>
          <TextField
            id="verification_code"
            name="verification_code"
            value={values.verification_code}
            label={t("verify.verification_code")}
            error={touched.verification_code && Boolean(errors.verification_code)}
            required
            onChange={handleChange}
            fullWidth
            helperText={touched.verification_code && errors.verification_code}
            sx={{
              margin: "0.5rem 0 1.5rem",
              maxWidth: { xs: "100%", sm: 350 },
            }}
          />
          {submitError && (
            <Typography paragraph color="red">
              {t(submitError)}
            </Typography>
          )}
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={isSubmitting}
            fullWidth
            sx={{
              display: "block",
              lineHeight: 2,
              maxWidth: { xs: "100%", sm: 200 },
            }}
          >
            {t("verify.submit")}
          </Button>
          <Grid
            container
            spacing={2}
            alignItems={{ xs: "flex-start", md: "center" }}
            flexDirection={{ xs: "column", md: "row" }}
            className={styles.secondaryActions}
          >
            <Grid item>
              <Button onClick={() => handleNewRequest(setSubmitting)}>{t("verify.secondary.retry")}</Button>
            </Grid>
            <Grid item sx={{ display: { xs: "none", md: "block" } }}>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              <Button onClick={resetStep}>{t("verify.secondary.reset")}</Button>
            </Grid>
            <Grid item sx={{ display: { xs: "none", md: "block" } }}>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              <Button onClick={resetStep}>{t("verify.secondary.cancel")}</Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default VerifyStep;
