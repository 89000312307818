import { useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Formik, Form, FormikHelpers } from "formik";
import { Typography, TextField, Button } from "@material-ui/core";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";

import { register } from "@/services/phone";
import { StepProps } from "@/components/NotificationForm";
import { timeToMinute } from "@/utils";

interface FormValues {
  phone_number: string;
}

const initialValues: FormValues = {
  phone_number: "",
};

const RegisterStep: React.FC<StepProps> = ({ attempt, storeAttempt, nextStep }) => {
  const { t } = useTranslation("notification");
  const { locale } = useRouter();
  const [submitError, setSubmitError] = useState("");

  const validate = ({ phone_number }: FormValues) => {
    const errors: { [I: string]: string } = {};
    if (!phone_number) errors.phone_number = t("form.error.required");
    if (!isValidPhoneNumber(phone_number, "CA")) {
      errors.phone_number = t("register.phone_number.invalid");
    }
    return errors;
  };

  const submit = async (phone_number: string) => {
    const { number: intlPhoneNumber } = parsePhoneNumber(phone_number, "CA");
    const res = await register({
      phone_number: intlPhoneNumber,
      language: locale!,
    });

    if (res.error) {
      setSubmitError("form.error.submit");
    } else {
      setSubmitError("");
      storeAttempt({
        phone_number: intlPhoneNumber,
        timestamp: new Date().getTime(),
      });
      nextStep();
    }
  };

  const handleSubmit = ({ phone_number }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setTimeout(() => {
      const now = new Date().getTime();
      if (!attempt) {
        submit(phone_number);
      } else if (timeToMinute(now - attempt.timestamp) > 1) {
        submit(phone_number);
      } else {
        setSubmitError("form.error.request_limit");
        setSubmitting(false);
      }
    }, 1000);
  };

  return (
    <Formik validate={validate} onSubmit={handleSubmit} initialValues={initialValues}>
      {({ values, handleChange, touched, errors, isSubmitting }) => (
        <Form>
          <Typography component="h2" variant="h3">
            {t("register.subtitle")}
          </Typography>
          <Typography paragraph>{t("register.description")}</Typography>
          <TextField
            id="phone_number"
            type="tel"
            name="phone_number"
            label={t("register.phone_number")}
            value={values.phone_number}
            error={touched.phone_number && Boolean(errors.phone_number)}
            required
            onChange={handleChange}
            fullWidth
            helperText={touched.phone_number && errors.phone_number}
            sx={{
              margin: "0.5rem 0 1.5rem",
              maxWidth: { xs: "100%", sm: 350 },
            }}
          />
          {submitError && (
            <Typography paragraph color="red">
              {t(submitError)}
            </Typography>
          )}
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={isSubmitting}
            fullWidth
            sx={{
              display: "block",
              lineHeight: 2,
              maxWidth: { xs: "100%", sm: 180 },
            }}
          >
            {t("register.submit")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterStep;
